<template>
  <div class="card mb-4">
    <div class="card-header">
      <h5 class="card-title mb-0">Pricing</h5>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <label class="form-label" for="ecommerce-product-price"
          >Base Price</label
        >
        <cleave
          id="number"
          v-model="price"
          class="form-control"
          :raw="false"
          :options="options.number"
          placeholder="10,000"
        />
      </div>

      <div class="mb-3">
        <label class="form-label" for="ecommerce-product-discount-price"
          >Discounted Price</label
        >
        <cleave
          id="number"
          v-model="DiscPrice"
          class="form-control"
          :raw="false"
          :options="options.number"
          placeholder="10,000"
        />
      </div>

      <div class="form-check mb-2">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="price-charge-tax"
          checked
        />
        <label class="form-label" for="price-charge-tax">
          Charge tax on this product
        </label>
      </div>

      <div
        class="d-flex justify-content-between align-items-center border-top pt-3"
      >
        <h6 class="mb-0">In stock</h6>
        <div class="w-25 d-flex justify-content-end">
          <label class="switch switch-primary switch-sm me-4 pe-2">
            <input type="checkbox" class="switch-input" checked="" />
            <span class="switch-toggle-slider">
              <span class="switch-on">
                <span class="switch-off"></span>
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    Cleave,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",

          noImmediatePrefix: true,

          prefix: "TND:     ",
          delimiter: "|",
        },
      },
      profileFile: null,
      optionsStock: [],
      price: null,
      DiscPrice: null,
    };
  },
  methods: {
    resetForm() {},
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
